import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'bspokeit';
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) {
    if (environment && environment.production) {
      this.document
        .getElementById('appFavicon')
        .setAttribute('href', '/static/client/favicon.ico');
    }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  // onActivate(event) {
  //   window.scroll(0, 0);
  // }
}
