import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingLayoutComponent } from './components/layouts/landing-layout/landing-layout.component';
import { ScrollToDirective } from './directives/scroll-to.directive';

const exportedClasses = [ScrollToDirective, LandingLayoutComponent];

@NgModule({
  imports: [CommonModule, NgbModule, RouterModule],
  declarations: exportedClasses,
  exports: exportedClasses,
})
export class SharedModule {}
